export default function sortNormalize(sortBy, sortDesc) {
  const length = sortBy?.length
  if (!length) {
    return null
  }
  if (sortBy.length !== sortDesc.length) {
    // eslint-disable-next-line no-console
    console.error('unequal sorting params length', sortBy, sortDesc)
  }
  let result = ''
  for (let i = 0; i < length; i++) {
    result += `${sortDesc[i] ? '-' : ''}${sortBy[i]},`
  }
  return result
}



export default {
  name: 'App',
  components: {
  },
  data() {
    return {
    }
  },
  methods: {

  }
  // middleware: 'notAuthenticated'
}

import { createNamespacedHelpers } from 'vuex'
import { DefineActions, DefineGetters, DefineMutations } from 'vuex-type-helper'

export interface State {
  errorObject: {}
}

export interface Actions {}

export interface Mutations {
  clearErrorObject: void
  clearErrorObjectField: string
  setErrorObject: Object
}

export interface Getters {}

export function state() {
  const state: State = {
    errorObject: {}
  }
  return state
}

export const getters: DefineGetters<Getters, State> = {}

export const actions: DefineActions<Actions, State, Mutations, Getters> = {}

export const mutations: DefineMutations<Mutations, State> = {

  clearErrorObject(state) {
    state.errorObject = {}
  },

  clearErrorObjectField(state, field: string) {
    (state.errorObject as any)[field] = undefined
  },

  setErrorObject(state, errorObject: Object) {
    state.errorObject = errorObject
  }
}

/**
 * Store modul pro errory ve formulářích - v helpers/handleErrorResponse.js voláme mutace na základě odpovědí z API
 * @module store/errors
 */
export const {
  mapState,
  mapGetters,
  mapMutations,
  mapActions
} = createNamespacedHelpers<State, Getters, Mutations, Actions>('errors')

export const errors = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}



export default {
  name: 'App',
  auth: false,
  middleware: ['keepUser', 'redirect'],
  watch: {
    '$route.path'() {
      this.$store.commit('errors/clearErrorObject')
    }
  }
}

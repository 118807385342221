export function getActiveIcon(is_active) {
  return is_active ? 'mdi-check-circle-outline' : 'mdi-close-circle-outline'
}

export function getActiveColor(is_active) {
  return is_active ? 'success' : 'red'
}

export function moneyNumberFormat(value) {
  return new Intl.NumberFormat('cs').format(value)
}

export function percentFormat(value, decimals = 2) {
  let percentValue
  if (Number.isNaN(value)) {
    percentValue = 0
  } else {
    percentValue = parseFloat(value * 100).toFixed(decimals)
  }
  return `${percentValue} %`
}

export function dateTimeFormat(value) {
  const date = new Date(value)
  return new Intl.DateTimeFormat('cs-CZ', { dateStyle: 'long', timeStyle: 'short' }).format(date)
}

export function dateFormat(value) {
  try {
    const date = new Date(value)
    return new Intl.DateTimeFormat('cs-CZ', { dateStyle: 'long' }).format(date)
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error)
    return null
  }
}

export function getObjectPath(theObject, item, path = '', returnParent = false) {
  // Vrací cestu ke konkrétní proměnné v objektu
  // pokud je returnParent true, tak vrací cestu k parent proměnné (pokud je array)
  let result = ''

  // RETURNS PATH TO OBJECT
  if (theObject === item) {
    return `${path}`
  }

  for (const prop in theObject) {
    // RETURNS PATH TO PARENT OF OBJECT
    if (returnParent && Array.isArray(theObject) && theObject.includes(item)) {
      return `${path}`
    }

    const new_path = path.length ? `${path}.${prop}` : prop

    if (theObject[prop] instanceof Object) {
      result += getObjectPath(theObject[prop], item, new_path, returnParent)
    }
  }

  return result
}

export function getObjectBranch(theObject, path = '') {
  // vytáhne pointer na hodnotu v objektu podle zadané cesty
  // nejčastěji používám pro vytáhnutí pointeru v Offer objektu
  // na kterém potom provídám změny
  let schema = theObject
  const pathList = path.split('.')

  for (let i = 0; i < pathList.length - 1; i++) {
    const pathElem = pathList[i]
    if (!schema[pathElem]) {
      schema[pathElem] = {}
    }
    schema = schema[pathElem]
  }

  return schema[pathList[pathList.length - 1]]
}

export function sumPriceCardItems(card) {
  // vypočítá cenu itemů na kartě
  if (!card) {
    return 0
  }

  return card.card_items.reduce((total, item) => {
    total += parseInt(item.price_sum)
    return total
  }, 0)
}

export function sumPriceCartItemsRelativeDiscount(card) {
  // vypočítá celkovou slevu itemů na kartě
  if (!card) {
    return 0
  }

  return card.card_items.reduce((total, item) => {
    total += parseInt(item.relative_discount_amount)
    return total
  }, 0)
}

export function sumPriceCartItemsHotelDiscount(card) {
  // vypočítá hotelovou slevu itemů na kartě
  if (!card) {
    return 0
  }

  return card.card_items.reduce((total, item) => {
    total += parseInt(item.hotel_discount_amount)
    return total
  }, 0)
}

export function sumPriceCards(cardList) {
  // vypočítá celkovou cenu karet
  if (!cardList.length) {
    return 0
  }

  return cardList.reduce((total, item) => {
    if (item.card.card_type !== 'cost') {
      total.price += parseInt(item.card.price_sum)
      total.discount += parseInt(item.card.discount_amount_sum)
    }

    return total
  }, { price: 0, discount: 0 })
}

export function timeSince(previous, current) {
  if (!current) { current = new Date() }
  // console.log('timeSince', { previous, current })

  const msPerMinute = 60 * 1000
  const msPerHour = msPerMinute * 60
  const msPerDay = msPerHour * 24
  const msPerMonth = msPerDay * 30
  const msPerYear = msPerDay * 365

  const elapsed = new Date(current) - new Date(previous)
  // console.log('timeSince', { elapsed })
  let number, type

  if (elapsed < msPerMinute) {
    // return Math.round(elapsed/1000) + ' seconds ago';
    number = Math.round(elapsed / 1000)
    type = number === 1 ? 'sekundou' : 'sekundami'
  } else if (elapsed < msPerHour) {
    // return Math.round(elapsed/msPerMinute) + ' minutes ago';
    number = Math.round(elapsed / msPerMinute)
    type = number === 1 ? 'minutou' : 'minutami'
  } else if (elapsed < msPerDay) {
    // return Math.round(elapsed/msPerHour ) + ' hours ago';
    number = Math.round(elapsed / msPerHour)
    type = number === 1 ? 'hodinou' : 'hodinami'
  } else if (elapsed < msPerMonth) {
    // return 'approximately ' + Math.round(elapsed/msPerDay) + ' days ago';
    number = Math.round(elapsed / msPerDay)
    type = number === 1 ? 'dnem' : 'dny'
  } else if (elapsed < msPerYear) {
    // return 'approximately ' + Math.round(elapsed/msPerMonth) + ' months ago';
    number = Math.round(elapsed / msPerMonth)
    type = number === 1 ? 'měsícem' : 'měsíci'
  } else {
    // return 'approximately ' + Math.round(elapsed/msPerYear ) + ' years ago';
    number = Math.round(elapsed / msPerYear)
    type = number === 1 ? 'rokem' : number <= 4 ? 'roky' : 'lety'
  }
  // console.log('timeSince', { number, type })
  return { number, type }
}

/*
var aDay = 24*60*60*1000;
console.log(timeSince(new Date(Date.now()-aDay)));
console.log(timeSince(new Date(Date.now()-aDay*2))); */

import { DefineActions, DefineGetters, DefineMutations } from 'vuex-type-helper'
import { createNamespacedHelpers } from 'vuex'
import { Country, CountryService } from '~/service/api'

export interface State {
  list: Country[],
  loading: boolean
}

export interface Mutations {
  setList: Country[]
  setLoading: boolean
}

export interface Actions {
  getList: object | {}
}

export interface Getters {
  countriesOptions: void
}

export function state() {
  const state: State = {
    list: [],
    loading: false
  }
  return state
}

export const getters: DefineGetters<Getters, State> = {
  countriesOptions(state) {
    return state.list.map(country => ({ text: country.iso_code, value: country.id }))
  }
}

export const mutations: DefineMutations<Mutations, State> = {
  setList(state, list) {
    state.list = list
  },
  setLoading(state, loading) {
    state.loading = loading
  }
}

export const actions: DefineActions<Actions, State, Mutations, Getters> = {
  async getList({ commit }) {
    commit('setLoading', true)
    await CountryService.countryList({
      pageSize: 300
    }).then((res) => {
      commit('setList', res.results)
      commit('setLoading', false)
    })
  }
}

export const {
  mapState,
  mapGetters,
  mapMutations,
  mapActions
} = createNamespacedHelpers<State, Getters, Mutations, Actions>('countries')

export const countries = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

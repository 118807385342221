import Vue from 'vue'
import { moneyNumberFormat, dateTimeFormat, getActiveIcon, getActiveColor, dateFormat, percentFormat } from '~/helpers/functions'
import { JOB_TYPE_NAMES, OFFER_STATE_OPTIONS } from '~/helpers/constants'

Vue.filter('errorToState', (value) => {
  if (value === undefined || value === null) { return null }
  return !value // if value exists, return false meaning invalid input
})

Vue.filter('moneyFormat', (value) => {
  if (value === undefined || value === null) { return null }
  return moneyNumberFormat(value)
})

Vue.filter('percentFormat', (value) => {
  if (value === undefined || value === null) { return null }
  return percentFormat(value)
})

Vue.filter('dateTimeFormat', (value) => {
  if (value === undefined || value === null) { return null }
  return dateTimeFormat(value)
})

Vue.filter('dateFormat', (value) => {
  if (value === undefined || value === null) { return null }
  return dateFormat(value)
})

Vue.filter('activeIcon', (value) => {
  if (value === undefined || value === null) { return null }
  return getActiveIcon(value)
})

Vue.filter('activeColor', (value) => {
  if (value === undefined || value === null) { return null }
  return getActiveColor(value)
})

Vue.filter('jobName', (value) => {
  if (!value) { return '' }
  return Object.prototype.hasOwnProperty.call(JOB_TYPE_NAMES, value) ? JOB_TYPE_NAMES[value] : '-'
})

Vue.filter('offerState', (value) => {
  if (!value) { return '' }
  const state = OFFER_STATE_OPTIONS.find(state => state.value === value)
  return state.text || '-'
})

Vue.filter('defaultString', (value) => {
  if (value) { return value }

  return '-'
})

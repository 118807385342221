import { DefineActions, DefineGetters, DefineMutations } from 'vuex-type-helper'
import { createNamespacedHelpers } from 'vuex'
import { BulkUpdateRelativeDiscount, CardItemService } from '~/service/api'

export interface State {
  draft: BulkUpdateRelativeDiscount,
  list: [],
  hotelUpdating: Boolean
}

export interface Actions {
  update: void
  updateHotel: void
}

export interface Mutations {
  clearDraft: void
  clearList: void
  setDraft: BulkUpdateRelativeDiscount
  setList: []
  updateDraftField: { field: string, value: any },
  setHotelUpdating: boolean
}

export interface Getters {}

export function state() {
  const state: State = {
    draft: new BulkUpdateRelativeDiscount(),
    list: [],
    hotelUpdating: false
  }
  return state
}

export const getters: DefineGetters<Getters, State> = {}

export const actions: DefineActions<Actions, State, Mutations, Getters> = {
  async update({ state, commit }) {
    await CardItemService.cardItemBulkUpdateDiscount({
      data: state.draft
    }).then((response) => {
      commit('setList', response)
    })
  },
  async updateHotel({ state, commit }) {
    commit('setHotelUpdating', true)
    const response = await CardItemService.cardItemBulkUpdateHotelDiscount({
      data: state.draft
    })
    commit('setList', response)
    commit('setHotelUpdating', false)
  }
}

export const mutations: DefineMutations<Mutations, State> = {
  clearDraft(state) {
    state.draft = new BulkUpdateRelativeDiscount()
  },
  clearList(state) {
    state.list = []
  },
  setDraft(state, itemDiscount) {
    state.draft = new BulkUpdateRelativeDiscount(itemDiscount)
  },
  setList(state, list) {
    state.list = list
  },
  updateDraftField(state, { field, value }) {
    (state.draft as any)[field] = value
  },
  setHotelUpdating(state, value) {
    state.hotelUpdating = value
  }
}

export const {
  mapState,
  mapGetters,
  mapMutations,
  mapActions
} = createNamespacedHelpers<State, Getters, Mutations, Actions>('carditems')

export const carditems = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

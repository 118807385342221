import { DefineActions, DefineGetters, DefineMutations } from 'vuex-type-helper'
import { createNamespacedHelpers } from 'vuex'
import { CardService } from '~/service/api'
import { CustomCard } from '~/models/Cards'

export interface State {
  detail: CustomCard,
  children: [],
  draft: CustomCard,
  list: CustomCard[]
  openedCards: []
  pagination: {}
}

export interface Actions {
  create: void
  copy: string
  delete: string
  get: string
  getList: void
  update: void
}

export interface Mutations {
  clear: void,
  clearDraft: void,
  clearList: void,
  createCard: object,
  set: CustomCard,
  setDraft: CustomCard,
  setChildren: [],
  setList: CustomCard[],
  setPagination: {},
  updateCardField: { field: string, value: any }
  updateDraftCardField: { field: string, value: any }
  updateOpenedCards: [],
}

export interface Getters {}

export function state() {
  const state: State = {
    detail: new CustomCard(),
    draft: new CustomCard(),
    children: [],
    list: [],
    openedCards: [],
    pagination: { count: null, next: null, previous: null, page: null, num_pages: null, page_size: null }
  }
  return state
}

export const getters: DefineGetters<Getters, State> = {}

export const actions: DefineActions<Actions, State, Mutations, Getters> = {
  async get({ commit }, id) {
    await CardService.cardRead({
      id
    }).then((res) => {
      commit('set', res as CustomCard)
    })
  },
  async create({ commit, state }) {
    await CardService.cardCreate({
      data: state.draft
    }).then((res) => {
      const responseCard = new CustomCard(res)
      commit('set', responseCard)
      const children = []
      if (responseCard.children) {
        for (const card of responseCard.children) {
          children.push(new CustomCard(card))
        }
      }
      commit('setChildren', children as [])
    })
  },
  async getList({ commit }) {
    await CardService.cardList().then((res) => {
      commit('setList', res.results)
      commit('setPagination', res.pagination)
    })
  },
  async update({ state, commit }) {
    await CardService.cardUpdate({
      data: state.draft,
      id: state.draft.id ?? ''
    }).then((res) => {
      commit('set', new CustomCard(res))
      commit('setChildren', [...res.card_items ?? []] as [])
    })
  },
  async delete({}, id: string) {
    await CardService.cardDelete({
      id
    })
  },
  async copy({ commit }, id: string) {
    await CardService.cardCopy({
      id,
      data: {}
    }).then((response) => {
      commit('set', response.card as unknown as CustomCard)
      if (response.children.length) {
        commit('setChildren', response.children as [])
      } else {
        commit('setChildren', response.card.card_items as [])
      }
    })
  }
}

export const mutations: DefineMutations<Mutations, State> = {
  createCard(state: State, data: object) {
    state.detail = new CustomCard(data)
    state.draft = new CustomCard(data)
  },
  clear(state) {
    state.detail = new CustomCard()
  },
  clearDraft(state) {
    state.draft = new CustomCard()
  },
  clearList(state) {
    state.list = []
  },
  set(state, card) {
    // při new CustomCard(card) nelze dohledat path v offerDetail
    state.detail = card
  },
  setChildren(state, children) {
    state.children = children
  },
  setDraft(state, card) {
    state.draft = new CustomCard(card)
  },
  setList(state, list) {
    state.list = list
  },
  setPagination(state, pagination) {
    state.pagination = pagination
  },
  updateCardField(state, { field, value }) {
    (state.detail as any)[field] = value
  },
  updateDraftCardField(state, { field, value }) {
    (state.draft as any)[field] = value
  },
  updateOpenedCards(state, cardsIds) {
    state.openedCards = cardsIds
  }
}

export const {
  mapState,
  mapGetters,
  mapMutations,
  mapActions
} = createNamespacedHelpers<State, Getters, Mutations, Actions>('cards')

export const cards = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

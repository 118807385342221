import { DefineActions, DefineGetters, DefineMutations } from 'vuex-type-helper'
import { createNamespacedHelpers } from 'vuex'
import { CompanyService } from '~/service/api'
import { CustomCompany } from '~/models/Company'
import sortNormalize from '~/helpers/urlSortParamsNormalize'

export interface State {
  detail: CustomCompany,
  filter: {
    q: string | null
    isActive: boolean | null,
    workersId: string | null,
    sortBy: Array<string> | null,
    sortDesc: Array<boolean> | null
  }
  list: CustomCompany[]
  pagination: { count: null, page: number, page_size: number }
  loading: boolean
}

export interface Actions {
  create: void,
  get: string,
  getList: object | {},
  update: void,
  delete: string
}

export interface Mutations {
  clear: void,
  clearList: void,
  set: CustomCompany,
  setList: CustomCompany[],
  setLoading: boolean,
  setPagination: { count: null, page: 1, page_size: 20 },
  setFilter: {
    q: string | null
    isActive: boolean | null,
    workersId: string | null,
    sortBy: Array<string> | null,
    sortDesc: Array<boolean> | null
  },
  updateCompanyField: { field: string, value: any },
  updateFilterField: { field: string, value: any },
  clearCompanyFilter: void,
}

export interface Getters {
  companiesOptions: void
}

export function state() {
  const state: State = {
    detail: new CustomCompany(),
    filter: {
      q: null,
      isActive: null,
      workersId: null,
      sortBy: [],
      sortDesc: []
    },
    list: [],
    pagination: { count: null, page: 1, page_size: 20 },
    loading: false
  }
  return state
}

export const getters: DefineGetters<Getters, State> = {
  companiesOptions(state) {
    return state.list.map(company => ({ text: company.name, value: company.id }))
  }
}

export const actions: DefineActions<Actions, State, Mutations, Getters> = {
  async create({ state }) {
    return await CompanyService.companyCreate(
      {
        data: state.detail
      }
    )
  },
  async get({ commit }, id) {
    await CompanyService.companyRead({
      id
    }).then(res => commit('set', new CustomCompany(res)))
  },
  async getList({ commit, state }, params: any) {
    commit('setLoading', true)
    await CompanyService.companyList({
      pageSize: state.pagination.page_size,
      page: state.pagination.page,
      ordering: params?.sortBy && params?.sortDesc ? sortNormalize(params.sortBy, params.sortDesc) as string : '',
      ...params
    }).then(
      (res) => {
        if ('pagination' in res) {
          commit('setList', res.results)
          commit('setPagination', res.pagination)
          commit('setLoading', false)
        } else {
          commit('setList', res)
        }
      }
    )
  },
  async update({ commit, state }) {
    await CompanyService.companyUpdate({
      data: state.detail,
      id: state.detail.id ?? ''
    }).then(res => commit('set', new CustomCompany(res)))
  },
  async delete({ }, id) {
    await CompanyService.companyDelete({
      id
    })
  }
}

export const mutations: DefineMutations<Mutations, State> = {
  clear(state) {
    state.detail = new CustomCompany()
  },
  clearList(state) {
    state.list = []
  },
  set(state, company) {
    state.detail = company
  },
  setList(state, list) {
    state.list = list
  },
  setLoading(state, loading) {
    state.loading = loading
  },
  setPagination(state, pagination) {
    state.pagination = pagination
  },
  setFilter(state, filter) {
    // Reset filter worker Id which might be applied after editing worker from workers store in workers table
    filter.workersId = null
    state.filter = filter
  },
  clearCompanyFilter(state) {
    state.filter = {
      q: null,
      isActive: null,
      workersId: null,
      sortBy: [],
      sortDesc: []
    }
  },
  updateCompanyField(state, { field, value }) {
    (state.detail as any)[field] = value
  },
  updateFilterField(state: State, { field, value }) {
    (state.filter as any)[field] = value
  }
}

export const {
  mapState,
  mapGetters,
  mapMutations,
  mapActions
} = createNamespacedHelpers<State, Getters, Mutations, Actions>('companies')

export const companies = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

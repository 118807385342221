import { Worker } from '~/service/api'

export class CustomWorker extends Worker {
  constructor(data: undefined | any = {}) {
    super()
    this.id = data.id || null
    this.first_name = data.first_name || null
    this.last_name = data.last_name || null
    this.company = data.company || null
    this.job = data.job || null
    this.phone_number = data.phone_number || null
    this.mobile_phone_number = data.mobile_phone_number || null
    this.email = data.email || null
    this.description = data.description || null
    this.is_active = data.is_active || true
  }
}

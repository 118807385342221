const MENU = [
  {
    header: true
  },
  {
    title: 'Zakázky',
    icon: 'pe-7s-note2',
    child: [
      {
        title: 'Nová zakázka',
        href: { name: 'zakazky-nova' },
        roleRestriction: 'admin'
      },
      {
        title: 'Zakázky',
        href: { name: 'zakazky' }
      }
    ]
  },
  {
    title: 'Klienti',
    icon: 'pe-7s-culture',
    roleRestriction: 'admin',
    child: [
      {
        title: 'Klienti',
        href: { name: 'spolecnost' }
      },
      {
        title: 'Nový klient',
        href: { name: 'spolecnost-nova' }
      },
      {
        title: 'Kontakty',
        href: { name: 'pracovnici' }
      },
      {
        title: 'Nový kontakt',
        href: { name: 'pracovnici-novy' }
      }
    ]
  },
  {
    title: 'Práce',
    icon: 'pe-7s-piggy',
    roleRestriction: 'admin',
    child: [
      {
        title: 'Práce', // originally "Seznam produktů"
        href: { name: 'cenik' }
      }
    ]
  },
  {
    title: 'Zaměstnanci',
    icon: 'pe-7s-users',
    roleRestriction: 'admin',
    child: [
      {
        title: 'Nový zaměstnanec',
        href: { name: 'zamestnanci-novy' }
      },
      {
        title: 'Seznam',
        href: { name: 'zamestnanci' }
      }
    ]
  }
]

export default function getMenuItems(roleRestriction = null) {
  return MENU
    .filter(item => item.header === true || !item.roleRestriction || item.roleRestriction === roleRestriction)
    .map((item) => {
      return {
        ...item,
        child: item.child?.filter(child => !child.roleRestriction || child.roleRestriction === roleRestriction)
      }
    })
}

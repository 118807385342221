import { DefineActions, DefineGetters, DefineMutations } from 'vuex-type-helper'
import { createNamespacedHelpers } from 'vuex'
import { CostCardItemService, CostCardItemWrite, CostCardItemRead } from '~/service/api'
import { CustomCostCardItem } from '~/models/CostItem'

export interface State {
  detail: CustomCostCardItem,
  draft: CustomCostCardItem,
  list: CustomCostCardItem[]
  costCardItemUserCardItems: CostCardItemRead[]
  pagination: {}
}

export interface Actions {
  bulkDelete: []
  getCostCardItemUserCardItems: string
  create: void
  update: void
  delete: string
}

export interface Mutations {
  clear: void,
  clearDraft: void,
  clearList: void,
  set: CustomCostCardItem,
  setDraft: CustomCostCardItem,
  setList: CustomCostCardItem[],
  setCostCardItemUserCardItems: CostCardItemRead[]
  setPagination: {},
  updateCostField: { field: string, value: any }
  updateCostDraftField: { field: string, value: any }
}

export interface Getters {}

export function state() {
  const state: State = {
    detail: new CustomCostCardItem(),
    draft: new CustomCostCardItem(),
    costCardItemUserCardItems: [],
    list: [],
    pagination: { count: null, next: null, previous: null, page: null, num_pages: null, page_size: null }
  }
  return state
}

export const getters: DefineGetters<Getters, State> = {}

export const actions: DefineActions<Actions, State, Mutations, Getters> = {
  async create({ state, commit }) {
    await CostCardItemService.costCardItemCreate({
      data: {
        ...state.draft,
        quantity: 1
      } as unknown as CostCardItemWrite
    }).then((response) => {
      commit('set', response as unknown as CustomCostCardItem)
    })
  },
  async update({ state, commit }) {
    const data = {
      ...state.draft,
      quantity: 1,
      pricing_product: state.draft.pricing_product?.id
    }

    await CostCardItemService.costCardItemUpdate({
      id: state.draft.id ?? '',
      data: data as unknown as CostCardItemWrite
    }).then((response) => {
      commit('set', response as unknown as CustomCostCardItem)
    })
  },
  async delete({}, id) {
    await CostCardItemService.costCardItemDelete({
      id
    })
  },
  async bulkDelete({}, data) {
    for (const id of data) {
      await CostCardItemService.costCardItemDelete({
        id
      })
    }
  },
  async getCostCardItemUserCardItems({ commit }, offerId) {
    await CostCardItemService.costCardItemUserCardItems({
      offerId
    }).then((res) => {
      commit('setCostCardItemUserCardItems', res as [] as CostCardItemRead[])
    })
  }
}

export const mutations: DefineMutations<Mutations, State> = {
  clear(state) {
    state.detail = new CustomCostCardItem()
  },
  clearDraft(state) {
    state.draft = new CustomCostCardItem()
  },
  clearList(state) {
    state.list = []
  },
  set(state, cost) {
    state.detail = cost
  },
  setDraft(state, cost) {
    state.draft = new CustomCostCardItem(cost)
  },
  setList(state, list) {
    state.list = list
  },
  setPagination(state, pagination) {
    state.pagination = pagination
  },
  updateCostField(state, { field, value }) {
    (state.detail as any)[field] = value
  },
  updateCostDraftField(state, { field, value }) {
    (state.draft as any)[field] = value
  },
  setCostCardItemUserCardItems(state, payload) {
    state.costCardItemUserCardItems = payload
  }
}

export const {
  mapState,
  mapGetters,
  mapMutations,
  mapActions
} = createNamespacedHelpers<State, Getters, Mutations, Actions>('cost')

export const cost = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

import { createNamespacedHelpers } from 'vuex'
import { DefineActions, DefineGetters, DefineMutations } from 'vuex-type-helper'

import { UserService } from '~/service/api'
import { CustomUser } from '~/models/User'
import sortNormalize from '~/helpers/urlSortParamsNormalize'

export interface State {
  detail: CustomUser
  draft: CustomUser
  filter: {
    q: string | null,
    sortBy: Array<string> | null,
    sortDesc: Array<boolean> | null
  }
  // isLoading: boolean
  list: CustomUser[]
  pagination: { page: number, page_size: number }
  loading: boolean
  search: string
}

export interface Actions {
  create: void
  delete: string
  get: string
  getList: object
  update: void
}

export interface Mutations {
  clearSearch: void
  clear: void
  clearDraft: void
  set: CustomUser
  setDraft: CustomUser
  setList: CustomUser[]
  setListPagination: { page: number, page_size: number }
  setFilter: {
    q: string | null,
    sortBy: Array<string> | null,
    sortDesc: Array<boolean> | null
  }
  setListPaginationPage: number
  setListPaginationSize: number
  setLoading: boolean
  setSearch: string
  updateFilterField: { field: string, value: any },
  updateField: { field: string, value: any }
  updateEmployeeField: { field: string, value: any }
  updateEmployeeDraftField: { field: string, value: any }
}

export interface Getters {}

export function state() {
  const state: State = {
    filter: {
      q: null,
      sortBy: [],
      sortDesc: []
    },
    detail: new CustomUser(),
    draft: new CustomUser(),
    // isLoading: false,
    list: [],
    pagination: { page: 1, page_size: 50 },
    loading: false,
    search: ''
  }
  return state
}

export const getters: DefineGetters<Getters, State> = {}

export const actions: DefineActions<Actions, State, Mutations, Getters> = {
  async create({ commit, state }) {
    await UserService.userCreate({
      data: state.draft
    }).then((response) => {
      commit('set', response)
    })
  },

  async delete({}, id) {
    await UserService.userDelete({
      id
    })
  },

  async get({ commit }, id) {
    // const response = await UserService.userRead({
    await UserService.userRead({
      id
    }).then((res) => {
      commit('set', res)
      commit('setDraft', res)
    })
  },
  async getList({ commit, state }, params: any) {
    commit('setLoading', true)
    // const response = await UserService.userList({
    await UserService.userList({
      ...params,
      ordering: params?.sortBy && params?.sortDesc ? sortNormalize(params.sortBy, params.sortDesc) as string : '',
      pageSize: state.pagination.page_size,
      page: state.pagination.page
    }).then((res) => {
      if ('pagination' in res) {
        commit('setListPagination', res.pagination)
        commit('setList', res.results)
        commit('setLoading', false)
      } else {
        commit('setList', res)
      }
    })
  },

  async update({ commit, state }) {
    commit('setLoading', true)

    const response = await UserService.userUpdate({
      id: state.draft.id ?? '',
      data: state.draft
    })

    commit('setLoading', false)
    commit('set', response)
  }
}

export const mutations: DefineMutations<Mutations, State> = {
  clear(state: State) {
    state.detail = new CustomUser()
  },
  clearDraft(state: State) {
    state.draft = new CustomUser()
  },

  clearSearch(state: State) {
    state.search = ''
  },

  set(state: State, detail: CustomUser) {
    state.detail = detail
  },

  setDraft(state: State, detail: CustomUser) {
    state.draft = detail
    state.draft.job_types = detail.job_types_obj?.map(item => item.id)
  },

  setList(state: State, list) {
    state.list = list
  },

  setListPagination(state: State, pagination) {
    state.pagination = pagination
  },

  setListPaginationPage(state: State, page) {
    state.pagination.page = page
  },

  setListPaginationSize(state: State, page_size) {
    state.pagination.page_size = page_size
  },

  setLoading(state: State, loading) {
    state.loading = loading
  },

  setFilter(state, filter) {
    state.filter = filter
  },

  setSearch(state: State, search) {
    state.search = search
  },
  updateFilterField(state: State, { field, value }) {
    (state.filter as any)[field] = value
  },

  updateField(state: State, { field, value }) {
    (state.detail as any)[field] = value
  },
  updateEmployeeField(state, { field, value }) {
    (state.detail as any)[field] = value
  },
  updateEmployeeDraftField(state, { field, value }) {
    (state.draft as any)[field] = value
  }
}

export const {
  mapState,
  mapGetters,
  mapMutations,
  mapActions
} = createNamespacedHelpers<State, Getters, Mutations, Actions>('employees')

export const employees = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

import { createNamespacedHelpers } from 'vuex'
import { DefineActions, DefineGetters, DefineMutations } from 'vuex-type-helper'

import { UserService } from '~/service/api'
import { CustomUser } from '~/models/User'

export interface State {
  detail: CustomUser
  isLoading: boolean
  list: CustomUser[]
  listPagination: { page: number, page_size: number }
  search: string
}

export interface Actions {
  delete: string
  getList: object
  getSelf: string
  update: void
}

export interface Mutations {
  clearSearch: void
  set: CustomUser
  setList: CustomUser[]
  setListPagination: { page: number, page_size: number }
  setListPaginationPage: number
  setListPaginationSize: number
  setLoading: boolean
  setSearch: string
  updateField: { field: string, value: any }
}

export interface Getters {
  usersOptions: void
}

export function state() {
  const state: State = {
    detail: new CustomUser(),
    isLoading: false,
    list: [],
    listPagination: { page: 1, page_size: 50 },
    search: ''
  }
  return state
}

export const getters: DefineGetters<Getters, State> = {
  usersOptions(state) {
    if (!state.list?.length) {
      return null
    }
    return state.list.filter(user => user.show_in_input).map(user => ({ text: `${user.first_name} ${user.last_name}`, value: user.id }))
  }
}

export const actions: DefineActions<Actions, State, Mutations, Getters> = {

  async delete({}, id) {
    await UserService.userDelete({
      id
    })
  },

  async getList({ commit }) {
    commit('setLoading', true)

    const response = await UserService.userList({
      // page: rootState.users.listPagination.page,
      // page_size: rootState.users.listPagination.page_size
      // q: rootState.users.search
    })

    commit('setLoading', false)
    commit('setListPagination', response.pagination)
    commit('setList', response.results || response)
  },

  async getSelf({ commit }) {
    commit('setLoading', true)

    const response = await UserService.userSelfRead()

    commit('setLoading', false)
    commit('set', response)
  },

  async update({ commit, rootState }) {
    commit('setLoading', true)

    const response = await UserService.userSelfPartialUpdate({
      data: rootState.users.detail
    })

    commit('setLoading', false)
    commit('set', response)
  }
}

export const mutations: DefineMutations<Mutations, State> = {
  clearSearch(state: State) {
    state.search = ''
  },

  set(state: State, detail: CustomUser) {
    state.detail = detail
  },

  setList(state: State, list) {
    state.list = list
  },

  setListPagination(state: State, pagination) {
    state.listPagination = pagination
  },

  setListPaginationPage(state: State, page) {
    state.listPagination.page = page
  },

  setListPaginationSize(state: State, page_size) {
    state.listPagination.page_size = page_size
  },

  setLoading(state: State, isLoading) {
    state.isLoading = isLoading
  },

  setSearch(state: State, search) {
    state.search = search
  },

  updateField(state: State, { field, value }) {
    (state.detail as any)[field] = value
  }
}

export const {
  mapState,
  mapGetters,
  mapMutations,
  mapActions
} = createNamespacedHelpers<State, Getters, Mutations, Actions>('users')

export const users = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

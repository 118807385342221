import { PricingProduct } from '~/service/api'

export class CustomPricingProduct extends PricingProduct {
  constructor(data: undefined | any = {}) {
    super()
    this.id = data.id || null
    this.title = data.title || null
    this.title_en = data.title_en || null
    this.price = data.price || null
    this.description = data.description || null
    this.is_reservation_disabled = data.is_reservation_disabled || false
    this.deleted = data.deleted || false
  }
}

import { DefineGetters, DefineActions, DefineMutations } from 'vuex-type-helper'
import { CustomJob } from '~/models/Jobs'
import { JobsService } from '~/service/api'

export interface State {
  list: CustomJob[],
  loading: boolean
}

export interface Actions {
  getList: object
}

export interface Mutations {
  setList: CustomJob[]
  setLoading: boolean
}

export interface Getters {
  jobOptions: void
}

export function state() {
  const state: State = {
    list: [],
    loading: false
  }
  return state
}

export const getters: DefineGetters<Getters, State> = {
  jobOptions(state) {
    return state.list.map(job => ({ text: job.text, value: job.value }))
  }
}

export const actions: DefineActions<Actions, State, Mutations, Getters> = {
  async getList({ commit }) {
    commit('setLoading', true)
    const res = await JobsService.jobsList()
    const mappedResults = res.map((j: CustomJob) => { return { value: j.id, text: j.name } })
    commit('setList', mappedResults)
    commit('setLoading', false)
  }
}

export const mutations: DefineMutations<Mutations, State> = {
  setList(state: State, list) {
    state.list = list
  },
  setLoading(state: State, loading) {
    state.loading = loading
  }

}

import { Company } from '~/service/api'

export class CustomCompany extends Company {
  house_number: string

  constructor(data: undefined | any = {}) {
    super()
    this.id = data.id || null
    this.name = data.name || null
    this.responsible_person = data.responsible_person || null
    this.dealer = data.dealer || null
    this.phone_number = data.phone_number || null
    this.phone_number2 = data.phone_number2 || null
    this.mobile_phone_number = data.mobile_phone_number || null
    this.email = data.email || null
    this.reg_number = data.reg_number || null
    this.vat_number = data.vat_number || null
    this.website = data.website || null
    this.description = data.description || null
    this.surcharge = data.surcharge || null
    this.is_active = data.is_active || true
    this.street = data.street || null
    this.house_number = data.house_number || null
    this.city = data.city || null
    this.zip = data.zip || null
    this.country = data.country || null
    this.country_data = data.country_data || null
  }
}

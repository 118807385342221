

import { mapActions, mapState } from 'vuex'
import HeaderUserArea from '~/components/common/HeaderUserArea'

export default {
  name: 'Header',
  components: { HeaderUserArea },
  data() {
    return {
      contactDialog: false,
      isOpen: false,
      isOpenMobileMenu: false
    }
  },
  computed: {
    ...mapState({
      // company: state => state.company.company,
      loggedIn: state => state.auth.loggedIn,
      user: state => state.users.detail
    })
  },
  methods: {
    ...mapActions({
      // getUser: 'account/getUser'
    }),
    // logout: function () {
    //   this.$store.dispatch(AUTH_LOGOUT).then(() => this.$router.push('/login'))
    // },
    toggleMobile(className) {
      const el = document.body
      this.isOpen = !this.isOpen
      this.isOpen ? el.classList.add(className) : el.classList.remove(className)
    },

    toggleMobile2(className) {
      const el = document.body
      this.isOpenMobileMenu = !this.isOpenMobileMenu
      this.isOpenMobileMenu ? el.classList.add(className) : el.classList.remove(className)
    }
  }
  // mounted() {
  //   this.getUser()
  // },
}

import { User } from '~/service/api'

export class CustomUser extends User {

  constructor(data: undefined | any = {}) {
    super()
    this.id = data.id || null
    this.first_name = data.first_name || null
    this.last_name = data.last_name || null
    this.email = data.email || null
    this.phone = data.phone || null
    this.mobile_phone = data.mobile_phone || null
    this.description = data.description || null
    this.job_types = data.job_types || null
    this.job_types_obj = data.job_types_obj || null
    this.is_admin = data.is_admin || false
    this.hour_rate = data.hour_rate || false
  }
}

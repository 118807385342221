import { createNamespacedHelpers } from 'vuex'
import { DefineActions, DefineGetters, DefineMutations } from 'vuex-type-helper'

import { AccountsService, PasswordChange } from '~/service/api'

export interface State {
  changePassword: PasswordChange
  isLoading: boolean
}

export interface Actions {
  changePassword: void
}

export interface Mutations {
  clearChangePassword: void
  setLoading: boolean
  updateChangePasswordField: { field: string, value: any }
}

export interface Getters {}

export function state() {
  const state: State = {
    changePassword: new PasswordChange(),
    isLoading: false
  }
  return state
}

export const getters: DefineGetters<Getters, State> = {}

export const actions: DefineActions<Actions, State, Mutations, Getters> = {

  async changePassword({ commit, rootState }) {
    commit('setLoading', true)

    await AccountsService.accountsPasswordChangeCreate({
      data: rootState.accounts.changePassword
    })

    commit('setLoading', false)
    commit('clearChangePassword')
  }
}

export const mutations: DefineMutations<Mutations, State> = {
  clearChangePassword(state: State) {
    state.changePassword = new PasswordChange()
  },
  setLoading(state: State, isLoading) {
    state.isLoading = isLoading
  },
  updateChangePasswordField(state: State, { field, value }) {
    (state.changePassword as any)[field] = value
  }
}

export const {
  mapState,
  mapGetters,
  mapMutations,
  mapActions
} = createNamespacedHelpers<State, Getters, Mutations, Actions>('accounts')

export const accounts = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

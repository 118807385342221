import { DefineActions, DefineGetters, DefineMutations } from 'vuex-type-helper'
import { createNamespacedHelpers } from 'vuex'
import { IRequestOptions, Message, MessageService } from '~/service/api'

export interface State {
  detail: Message,
  draft: Message,
  list: Message[]
}

export interface Actions {
  get: IRequestOptions | undefined
  getList: { },
  create: void
  update: IRequestOptions | undefined
  delete: IRequestOptions | undefined
}

export interface Mutations {
  clear: void,
  clearDraft: void,
  clearList: void,
  set: Message,
  setDraft: Message,
  setList: Message[],
  updateMessageField: { field: string, value: any }
  updateMessageDraftField: { field: string, value: any }
}

export interface Getters {}

export function state() {
  const state: State = {
    detail: new Message(),
    draft: new Message(),
    list: []
  }
  return state
}

export const getters: DefineGetters<Getters, State> = {}

export const actions: DefineActions<Actions, State, Mutations, Getters> = {
  async create({ state }) {
    return await MessageService.messageCreate(
      {
        data: state.draft
      }
    )
  },
  async get({ commit }, options) {
    await MessageService.messageRead(options).then(res => commit('set', res))
  },
  async getList({ commit }, params: any) {
    await MessageService.messageList(
      params
    ).then(res => commit('setList', res))
  },
  async update({ state, commit }, options) {
    await MessageService.messageUpdate({
      data: state.draft
    },
    options
    ).then((response) => {
      commit('set', response)
    })
  },
  async delete({}, option) {
    await MessageService.messageDelete(option)
  }
}

export const mutations: DefineMutations<Mutations, State> = {
  clear(state) {
    state.detail = new Message()
  },
  clearDraft(state) {
    state.draft = new Message()
  },
  clearList(state) {
    state.list = []
  },
  set(state, message) {
    state.detail = message
  },
  setDraft(state, message) {
    state.draft = new Message(message)
  },
  setList(state, list) {
    state.list = list
  },
  updateMessageField(state, { field, value }) {
    (state.detail as any)[field] = value
  },
  updateMessageDraftField(state, { field, value }) {
    (state.draft as any)[field] = value
  }
}
export const {
  mapState,
  mapGetters,
  mapMutations,
  mapActions
} = createNamespacedHelpers<State, Getters, Mutations, Actions>('person')

export const message = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

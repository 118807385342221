import { DefineActions, DefineGetters, DefineMutations } from 'vuex-type-helper'
import { createNamespacedHelpers } from 'vuex'
import { OtherCardItemService } from '~/service/api'
import { CustomOtherCardItem } from '~/models/OtherItem'

export interface State {
  detail: CustomOtherCardItem,
  draft: CustomOtherCardItem,
  list: CustomOtherCardItem[]
  pagination: {}
}

export interface Actions {
  bulkDelete: []
  create: void
  update: void
  delete: string
}

export interface Mutations {
  clear: void,
  clearDraft: void,
  clearList: void,
  set: CustomOtherCardItem,
  setDraft: CustomOtherCardItem,
  setList: CustomOtherCardItem[],
  setPagination: {},
  updateField: { field: string, value: any }
  updateDraftField: { field: string, value: any }
}

export interface Getters {}

export function state() {
  const state: State = {
    detail: new CustomOtherCardItem(),
    draft: new CustomOtherCardItem(),
    list: [],
    pagination: { count: null, next: null, previous: null, page: null, num_pages: null, page_size: null }
  }
  return state
}

export const getters: DefineGetters<Getters, State> = {}

export const actions: DefineActions<Actions, State, Mutations, Getters> = {
  async create({ state, commit }) {
    await OtherCardItemService.otherCardItemCreate({
      data: state.draft
    }).then((response) => {
      commit('set', response)
    })
  },
  async update({ state, commit }) {
    await OtherCardItemService.otherCardItemUpdate({
      id: state.draft.id ?? '',
      data: state.draft
    }).then((response) => {
      commit('set', response)
    })
  },
  async delete({}, id) {
    await OtherCardItemService.otherCardItemDelete({
      id
    })
  },
  async bulkDelete({}, data) {
    for (const id of data) {
      await OtherCardItemService.otherCardItemDelete({
        id
      })
    }
  }
}

export const mutations: DefineMutations<Mutations, State> = {
  clear(state) {
    state.detail = new CustomOtherCardItem()
  },
  clearDraft(state) {
    state.draft = new CustomOtherCardItem()
  },
  clearList(state) {
    state.list = []
  },
  set(state, item) {
    state.detail = item
  },
  setDraft(state, item) {
    state.draft = new CustomOtherCardItem(item)
  },
  setList(state, list) {
    state.list = list
  },
  setPagination(state, pagination) {
    state.pagination = pagination
  },
  updateField(state, { field, value }) {
    (state.detail as any)[field] = value
  },
  updateDraftField(state, { field, value }) {
    (state.draft as any)[field] = value
  }
}

export const {
  mapState,
  mapGetters,
  mapMutations,
  mapActions
} = createNamespacedHelpers<State, Getters, Mutations, Actions>('other')

export const other = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

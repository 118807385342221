import { DefineActions, DefineGetters, DefineMutations } from 'vuex-type-helper'
import { createNamespacedHelpers } from 'vuex'
import { MaterialCardItemService, MaterialCardItemWrite } from '~/service/api'
import { CustomMaterialCardItem } from '~/models/MaterialItem'

export interface State {
  detail: CustomMaterialCardItem,
  draft: CustomMaterialCardItem,
  list: CustomMaterialCardItem[]
  pagination: {}
}

export interface Actions {
  bulkDelete: []
  create: void
  update: void
  delete: string
}

export interface Mutations {
  clear: void,
  clearDraft: void,
  clearList: void,
  set: CustomMaterialCardItem,
  setDraft: CustomMaterialCardItem,
  setList: CustomMaterialCardItem[],
  setPagination: {},
  updateMaterialField: { field: string, value: any }
  updateMaterialDraftField: { field: string, value: any }
}

export interface Getters {}

export function state() {
  const state: State = {
    detail: new CustomMaterialCardItem(),
    draft: new CustomMaterialCardItem(),
    list: [],
    pagination: { count: null, next: null, previous: null, page: null, num_pages: null, page_size: null }
  }
  return state
}

export const getters: DefineGetters<Getters, State> = {}

export const actions: DefineActions<Actions, State, Mutations, Getters> = {
  async create({ state, commit }) {
    await MaterialCardItemService.materialCardItemCreate({
      data: state.draft as unknown as MaterialCardItemWrite
    }).then((response) => {
      commit('set', response as unknown as CustomMaterialCardItem)
    })
  },
  async update({ state, commit }) {
    const data = { ...state.draft }
    if (typeof state.draft.pricing_product === 'object') {
      // TODO remove TS ignore after edit endpoint on backend (return whole object, no only a uuid)
      // @ts-ignore
      data.pricing_product = data.pricing_product.id
    }

    await MaterialCardItemService.materialCardItemUpdate({
      id: state.draft.id ?? '',
      data: data as unknown as MaterialCardItemWrite
    }).then((response) => {
      commit('set', response as unknown as CustomMaterialCardItem)
    })
  },
  async delete({}, id) {
    await MaterialCardItemService.materialCardItemDelete({
      id
    })
  },
  async bulkDelete({}, data) {
    for (const id of data) {
      await MaterialCardItemService.materialCardItemDelete({
        id
      })
    }
  }
}

export const mutations: DefineMutations<Mutations, State> = {
  clear(state) {
    state.detail = new CustomMaterialCardItem()
  },
  clearDraft(state) {
    state.draft = new CustomMaterialCardItem()
  },
  clearList(state) {
    state.list = []
  },
  set(state, material) {
    state.detail = material
  },
  setDraft(state, material) {
    state.draft = new CustomMaterialCardItem(material)
  },
  setList(state, list) {
    state.list = list
  },
  setPagination(state, pagination) {
    state.pagination = pagination
  },
  updateMaterialField(state, { field, value }) {
    (state.detail as any)[field] = value
  },
  updateMaterialDraftField(state, { field, value }) {
    (state.draft as any)[field] = value
  }
}

export const {
  mapState,
  mapGetters,
  mapMutations,
  mapActions
} = createNamespacedHelpers<State, Getters, Mutations, Actions>('material')

export const material = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

import Cookies from 'js-cookie'

import getGlobal from '~/helpers/getGlobal'
import handleErrorResponse from '~/helpers/handleErrorResponse'

import { serviceOptions } from '~/service/api'

export default function({ $axios }) {
  const globalObject = getGlobal()

  $axios.onError((err) => {
    if (err.code === 'ERR_CANCELED') {
      return Promise.reject(err)
    }
    const handledError = handleErrorResponse(err.response)

    if (window.$nuxt) {
      if (handledError.errorObject) { window.$nuxt.$store.commit('errors/setErrorObject', handledError.errorObject) }
      if (handledError.message) { window.$nuxt.$toast.error(handledError.message) }

      if (err.response.status === 401) { return Promise.resolve(handledError) }
      return Promise.reject(handledError)
    }

    return Promise.reject(err)
  })

  $axios.onResponse((response) => {
    if (response.data && globalObject && globalObject.$nuxt) {
      globalObject.$nuxt.$toasted.success(response.data.detail, { duration: 5000 })
    }
  })

  $axios.defaults.baseURL = process.env.API_URL
  $axios.defaults.withCredentials = true
  $axios.defaults.headers.common['Accept-Language'] = navigator.language
  $axios.defaults.headers.common.Authorization = Cookies.get('auth._token.local')

  serviceOptions.axios = $axios
  // serviceOptions.axios.defaults.baseURL = process.env.API_URL
}

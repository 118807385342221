import { Card, GeneralCardItem } from '~/service/api'

export class CustomCard extends Card {
  card_items: GeneralCardItem[]
  relative_discount: string
  hotel_discount_percentage: string
  children: []

  constructor(data: undefined | any = {}) {
    super()
    this.id = data.id || null
    this.parent = data.parent || null
    this.title = data.title || null
    this.card_type = data.card_type || null
    this.price_sum = data.price_sum || null
    this.discount_amount_sum = data.discount_amount_sum || 0
    this.hotel_discount_amount_sum = data.hotel_discount_amount_sum || 0
    this.sort_order = data.sort_order || null
    this.card_items = data.card_items || []
    this.relative_discount = data.relative_discount || null
    this.hotel_discount_percentage = data.hotel_discount_percentage || null
    this.children = data.children || []
  }
}

import { DefineActions, DefineGetters, DefineMutations } from 'vuex-type-helper'
import { createNamespacedHelpers } from 'vuex'
import { CategoryService } from '~/service/api'
import { CustomCategory } from '~/models/Category'
import sortNormalize from '~/helpers/urlSortParamsNormalize'

export interface State {
  detail: CustomCategory,
  draft: CustomCategory,
  list: CustomCategory[]
  filter: {
    q: string | null,
    sortBy: Array<string> | null,
    sortDesc: Array<boolean> | null
  }
  pagination: { count: null, page: number, page_size: number }
  loading: boolean
}

export interface Actions {
  create: void,
  get: string,
  getList: {
    q: string | undefined,
    sortBy: Array<string> | null,
    sortDesc: Array<boolean> | null
  },
  update: void,
  delete: string,
}

export interface Mutations {
  clear: void,
  clearDraft: void,
  clearList: void,
  set: CustomCategory,
  setDraft: CustomCategory,
  setList: CustomCategory[],
  setLoading: boolean,
  setPagination: { count: null, page: 1, page_size: 20 },
  setFilter: {
    q: string | null,
    sortBy: Array<string> | null,
    sortDesc: Array<boolean> | null
  }
  updateFilterField: { field: string, value: any },
  updateCategoryField: { field: string, value: any },
  updateCategoryDraftField: { field: string, value: any }
}

export interface Getters {
  categoryOptions: void
}

export function state() {
  const state: State = {
    detail: new CustomCategory(),
    draft: new CustomCategory(),
    filter: {
      q: null,
      sortBy: [],
      sortDesc: []
    },
    list: [],
    pagination: { count: null, page: 1, page_size: 20 },
    loading: false
  }
  return state
}

export const getters: DefineGetters<Getters, State> = {
  categoryOptions(state) {
    return state.list.map(category => ({ text: category.title, value: category.id }))
  }
}

export const actions: DefineActions<Actions, State, Mutations, Getters> = {
  async create({ state }) {
    return await CategoryService.categoryCreate(
      {
        data: state.draft
      }
    )
  },

  async get({ commit }, id) {
    await CategoryService.categoryRead({
      id
    }).then(res => commit('set', res))
  },

  async getList({ commit, state }, params: {
    q?: string | undefined,
    sortBy?: Array<string> | null,
    sortDesc?: Array<boolean> | null
  }) {
    commit('setLoading', true)
    await CategoryService.categoryList({
      ...params,
      ordering: params?.sortBy && params?.sortDesc ? sortNormalize(params.sortBy, params.sortDesc) as string : '',
      pageSize: state.pagination.page_size,
      page: state.pagination.page
    }).then(
      (res) => {
        if ('pagination' in res) {
          commit('setList', res.results)
          commit('setPagination', res.pagination)
          commit('setLoading', false)
        } else {
          commit('setList', res)
        }
      }
    )
  },

  async update({ commit, state }) {
    await CategoryService.categoryUpdate({
      data: state.draft,
      id: state.draft.id ?? ''
    }).then(res => commit('set', res))
  },

  async delete({}, id) {
    await CategoryService.categoryDelete({
      id
    })
  }
}

export const mutations: DefineMutations<Mutations, State> = {
  clear(state) {
    state.detail = new CustomCategory()
  },
  clearDraft(state) {
    state.draft = new CustomCategory()
  },
  clearList(state) {
    state.list = []
  },
  set(state, company) {
    state.detail = company
  },
  setDraft(state, category) {
    state.draft = new CustomCategory(category)
  },
  setList(state, list) {
    state.list = list
  },
  setLoading(state, loading) {
    state.loading = loading
  },
  setFilter(state, filter) {
    state.filter = filter
  },
  setPagination(state, pagination) {
    state.pagination = pagination
  },
  updateFilterField(state: State, { field, value }) {
    (state.filter as any)[field] = value
  },
  updateCategoryField(state, { field, value }) {
    (state.detail as any)[field] = value
  },
  updateCategoryDraftField(state, { field, value }) {
    (state.draft as any)[field] = value
  }
}

export const {
  mapState,
  mapGetters,
  mapMutations,
  mapActions
} = createNamespacedHelpers<State, Getters, Mutations, Actions>('categories')

export const categories = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

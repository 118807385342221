import { DefineActions, DefineGetters, DefineMutations } from 'vuex-type-helper'
import { createNamespacedHelpers } from 'vuex'
import { WorkerService } from '~/service/api'
import { CustomWorker } from '~/models/Workers'
import sortNormalize from '~/helpers/urlSortParamsNormalize'

export interface State {
  detail: CustomWorker,
  filter: {
    q: string | null,
    companyId: string | null,
    isActive: boolean | null,
    sortBy: Array<string> | null,
    sortDesc: Array<boolean> | null
  }
  list: CustomWorker[],
  contactList: CustomWorker[]
  pagination: { count: null, page: number, page_size: number }
  loading: boolean,
  contactLoading: boolean,
  listSuggestions: CustomWorker[]
}

export interface Actions {
  create: void,
  get: string,
  getList: object | {},
  getListSuggestions: object | {},
  getContactList: string,
  update: void,
  delete: string,
}

export interface Mutations {
  clear: void,
  clearList: void,
  set: CustomWorker,
  setList: CustomWorker[],
  setContactList: CustomWorker[],
  setLoading: boolean,
  setContactLoading: boolean,
  setFilter: {
    q: string | null,
    companyId: null,
    isActive: null,
    sortBy: Array<string> | null,
    sortDesc: Array<boolean> | null
  },
  setPagination: { count: null, page: 1, page_size: 20 },
  updateFilterField: { field: string, value: any },
  updateWorkerField: { field: string, value: any },
  clearWorkerFilter: void,
  setListSuggestions: CustomWorker[]
}

export interface Getters {
  contactOptions: void
}

export function state() {
  const state: State = {
    detail: new CustomWorker(),
    filter: {
      q: null,
      companyId: null,
      isActive: null,
      sortBy: [],
      sortDesc: []
    },
    list: [],
    contactList: [],
    pagination: { count: null, page: 1, page_size: 20 },
    loading: false,
    contactLoading: false,
    listSuggestions: []
  }
  return state
}

export const getters: DefineGetters<Getters, State> = {
  contactOptions(state) {
    return state.listSuggestions.map(contact => ({ text: `${contact.first_name} ${contact.last_name}`, value: contact.id }))
  }
}

export const actions: DefineActions<Actions, State, Mutations, Getters> = {
  async create({ state }) {
    return await WorkerService.workerCreate(
      {
        data: state.detail
      }
    )
  },

  async get({ commit }, id) {
    await WorkerService.workerRead({
      id
    }).then(res => commit('set', res))
  },

  async getList({ commit, state }, params: any) {
    commit('setLoading', true)
    await WorkerService.workerList({
      ...params,
      ordering: params?.sortBy && params?.sortDesc ? sortNormalize(params.sortBy, params.sortDesc) as string : '',
      pageSize: state.pagination.page_size,
      page: state.pagination.page
    }).then(
      (res) => {
        if ('pagination' in res) {
          commit('setList', res.results)
          commit('setPagination', res.pagination)
          commit('setLoading', false)
        } else {
          commit('setList', res)
        }
      }
    )
  },

  async getListSuggestions({ commit }) {
    commit('setLoading', true)
    const res = await WorkerService.workerList({})
    commit('setListSuggestions', res)
    commit('setLoading', false)
  },

  async update({ commit, state }) {
    await WorkerService.workerUpdate({
      data: state.detail,
      id: state.detail.id ?? ''
    }).then(res => commit('set', res))
  },

  async delete({ }, id) {
    await WorkerService.workerDelete({
      id
    })
  },
  async getContactList({ commit }, companyId) {
    commit('setContactLoading', true)
    const res = await WorkerService.workerList({ companyId })
    commit('setContactList', res)
    commit('setContactLoading', false)
  }
}

export const mutations: DefineMutations<Mutations, State> = {
  clear(state) {
    state.detail = new CustomWorker()
  },
  clearList(state) {
    state.list = []
  },
  set(state, company) {
    state.detail = company
  },
  setList(state, list) {
    state.list = list
  },
  setLoading(state, loading) {
    state.loading = loading
  },
  setListSuggestions(state, list) {
    state.listSuggestions = list
  },
  setContactLoading(state, loading) {
    state.contactLoading = loading
  },
  setPagination(state, pagination) {
    state.pagination = pagination
  },
  setFilter(state, filter) {
    state.filter = filter
  },
  updateFilterField(state: State, { field, value }) {
    (state.filter as any)[field] = value
  },
  clearWorkerFilter(state: State) {
    state.filter = {
      q: null,
      companyId: null,
      isActive: null,
      sortBy: [],
      sortDesc: []
    }
  },
  updateWorkerField(state, { field, value }) {
    // Check whether value has a length and if not return datat type null
    if (value.length === 0) { value = null }
    (state.detail as any)[field] = value
  },
  setContactList(state, list) {
    state.contactList = list
  }
}

export const {
  mapState,
  mapGetters,
  mapMutations,
  mapActions
} = createNamespacedHelpers<State, Getters, Mutations, Actions>('workers')

export const workers = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

import { MaterialCardItemRead } from '~/service/api'

export class CustomMaterialCardItem extends MaterialCardItemRead {
  constructor(data: undefined | any = {}) {
    super()
    this.id = data.id || null
    this.note = data.note || null
    this.price = data.price || null
    this.relative_discount = data.relative_discount || null
    this.price_sum = data.price_sum || null
    this.sort_order = data.sort_order || null
    this.quantity = data.quantity || null
    this.amount_of_time = data.amount_of_time || null
    this.pricing_product = data.pricing_product || null
    this.card = data.card || null
    this.highlight = data.highlight || false
  }
}

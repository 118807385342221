export const cardTypes = ['room', 'table']

export const MATERIAL_CARD = 'material'
export const PERSONAL_CARD = 'personal'
export const PLACE_CARD = 'place'
export const ROOT_CARD = 'root'
export const OTHER_CARD = 'other'
export const COST_CARD = 'cost'

export const CARD_STATE_OFFER = 'offer'
export const CARD_STATE_ORDER = 'order'
export const CARD_STATE_CANCELLED = 'rejected'

export const JOB_TYPE_MANAGER = 'manager'
export const JOB_TYPE_TECHNICIAN = 'technician'
export const JOB_TYPE_BRIGADIER = 'brigadier'
export const JOB_TYPE_WAREHOUSEMAN = 'warehouseman'

export const JOB_TYPE_OPTIONS = [
  { text: 'Manažer', value: JOB_TYPE_MANAGER },
  { text: 'Technik', value: JOB_TYPE_TECHNICIAN },
  { text: 'Skladník', value: JOB_TYPE_WAREHOUSEMAN },
  { text: 'Brigádník', value: JOB_TYPE_BRIGADIER }
]

export const JOB_TYPE_NAMES = {
  manager: 'Manažer',
  technician: 'Technik',
  brigadier: 'Brigádník',
  warehouseman: 'Skladník'
}

export const OFFER_STATE_OPTIONS = [
  { text: 'Aktivní', value: 'offer' },
  { text: 'Client', value: 'reserved' },
  { text: 'Suspended', value: 'rejected' },
  { text: 'Finished', value: 'ended' }
]

export const OFFER_INVOICE_OPTIONS = [
  { text: 'Ano', value: true },
  { text: 'Ne', value: false }
]

export const OFFER_JOBS_OPTIONS = [
  { text: 'Graphic', value: '1' },
  { text: 'DTP', value: '2' },
  { text: 'Prepress', value: '3' }
]

export const testOfferList = [
  {
    id: 5,
    name: 'Test zakázka - 29.7.2021',
    email: 'test@test.cz',
    cards: [
      {
        id: 6,
        name: 'Místnost 354',
        type: PLACE_CARD,
        cards: [
          {
            id: 7,
            name: 'Personální karta',
            type: PERSONAL_CARD,
            persons: [
              { id: '1', text: 'Test text 1', note: 'Poznámka', person: 'Karel Hynek 1', hours: '80', price: '150', discount: '20', total: '1200' },
              { id: '2', text: 'Test text 2', note: 'Poznámka', person: 'Karel Hynek 2', hours: '80', price: '150', discount: '20', total: '1200' },
              { id: '3', text: 'Test text 3', note: 'Poznámka', person: 'Karel Hynek 3', hours: '80', price: '150', discount: '20', total: '1200' },
              { id: '4', text: 'Test text 4', note: 'Poznámka', person: 'Karel Hynek 4', hours: '80', price: '150', discount: '20', total: '1200' }
            ]
          },
          {
            id: 8,
            name: 'Materiální karta',
            type: MATERIAL_CARD,
            materials: [
              { id: '1', type: 'Test type 1', product: 'Produkt 1', note: 'Poznámka', number_pcs: '10 ks', number_days: '80', price: '150', discount: '20', total: '1200' },
              { id: '2', type: 'Test type 2', product: 'Produkt 2', note: 'Poznámka', number_pcs: '20 ks', number_days: '80', price: '150', discount: '20', total: '1200' },
              { id: '3', type: 'Test type 3', product: 'Produkt 3', note: 'Poznámka', number_pcs: '30 ks', number_days: '80', price: '150', discount: '20', total: '1200' },
              { id: '4', type: 'Test type 4', product: 'Produkt 4', note: 'Poznámka', number_pcs: '40 ks', number_days: '80', price: '150', discount: '20', total: '1200' }
            ]
          }
        ]
      },
      {
        id: 9,
        name: 'Místnost 486',
        type: 'room',
        cards: [
          {
            id: 11,
            name: 'Personální karta',
            type: 'personal',
            persons: [
              { id: '10', text: 'Test text 1', note: 'Poznámka', person: 'Karel Hynek 1', hours: '80', price: '150', discount: '20', total: '1200' },
              { id: '20', text: 'Test text 2', note: 'Poznámka', person: 'Karel Hynek 2', hours: '80', price: '150', discount: '20', total: '1200' },
              { id: '30', text: 'Test text 3', note: 'Poznámka', person: 'Karel Hynek 3', hours: '80', price: '150', discount: '20', total: '1200' },
              { id: '40', text: 'Test text 4', note: 'Poznámka', person: 'Karel Hynek 4', hours: '80', price: '150', discount: '20', total: '1200' }
            ]
          },
          {
            id: 10,
            name: 'Materiální karta',
            type: 'material',
            materials: [
              { id: '10', type: 'Test type 1', product: 'Produkt 1', note: 'Poznámka', number_pcs: '10 ks', number_days: '80', price: '150', discount: '20', total: '1200' },
              { id: '20', type: 'Test type 2', product: 'Produkt 2', note: 'Poznámka', number_pcs: '20 ks', number_days: '80', price: '150', discount: '20', total: '1200' },
              { id: '30', type: 'Test type 3', product: 'Produkt 3', note: 'Poznámka', number_pcs: '30 ks', number_days: '80', price: '150', discount: '20', total: '1200' },
              { id: '40', type: 'Test type 4', product: 'Produkt 4', note: 'Poznámka', number_pcs: '40 ks', number_days: '80', price: '150', discount: '20', total: '1200' }
            ]
          }
        ]
      },
      {
        id: 88,
        name: 'Materiální karta',
        type: 'material',
        materials: [
          { id: '1', type: 'Test type 1', product: 'Produkt 1', note: 'Poznámka', number_pcs: '10 ks', number_days: '80', price: '150', discount: '20', total: '1200' },
          { id: '2', type: 'Test type 2', product: 'Produkt 2', note: 'Poznámka', number_pcs: '20 ks', number_days: '80', price: '150', discount: '20', total: '1200' },
          { id: '3', type: 'Test type 3', product: 'Produkt 3', note: 'Poznámka', number_pcs: '30 ks', number_days: '80', price: '150', discount: '20', total: '1200' },
          { id: '4', type: 'Test type 4', product: 'Produkt 4', note: 'Poznámka', number_pcs: '40 ks', number_days: '80', price: '150', discount: '20', total: '1200' }
        ]
      }
    ]
  }
]

export const countryList = [{ text: 'CZ', value: 'CZ' }, { text: 'SK', value: 'SK' }, { text: 'SI', value: 'SI' }]
export const currencyList = [{ text: 'CZK', value: 'CZK' }, { text: 'EUR', value: 'EUR' }, { text: 'USD', value: 'USD' }, { text: 'GBP', value: 'GBP' }]
export const documentPrintTypes = [{ text: 'Podklad pro fakturaci', value: 5 }, { text: 'Nabídka', value: 6 }]
export const documentPrintFormat = [{ text: 'PDF', value: 'pdf' }, { text: 'EXCEL', value: 'xls' }]
export const documentPrintLanguage = [{ text: 'Česky', value: 'cs' }, { text: 'Anglicky', value: 'en' }]
export const documentPrintCurrency = [{ text: 'CZK', value: 'CZK' }, { text: 'EUR', value: 'EUR' }]

export const fileNameDict = {
  1: 'nabidka',
  2: 'pro-klienty-bez-slev',
  3: 'pro-klienty-s-prirazkou',
  4: 'pro-klienty-s-prirazkou-se-slevou',
  5: 'podklad k fakturaci',
  6: 'nabidka'
}

export const testProduct = {
  name: 'test'
}

export const testEmployee1 = {
  firstName: 'John',
  lastName: 'Back'
}
export const testEmployee2 = {
  firstName: 'Marie',
  lastName: 'White'
}

export const discussionPageSize = 10


import { SidebarMenu } from 'vue-sidebar-menu'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { mapState } from 'vuex'
import getMenuItems from '~/helpers/getMenuItems'

export default {
  components: { SidebarMenu, VuePerfectScrollbar },
  data: () => ({
    getMenuItems,
    collapsed: true,
    isOpen: false,
    sidebarActive: false,
    windowWidth: 0
  }),
  computed: {
    menu() {
      return getMenuItems(this.userDetail?.is_admin ? 'admin' : null)// getMenuItems(this)
    },
    ...mapState({
      userDetail: state => state.users.detail
    })
  },
  mounted() {
    this.$nextTick(function() {
      window.addEventListener('resize', this.getWindowWidth)
      // Init
      this.getWindowWidth()
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.getWindowWidth)
  },
  methods: {
    toggleBodyClass(className) {
      const el = document.body
      this.isOpen = !this.isOpen
      this.isOpen ? el.classList.add(className) : el.classList.remove(className)
    },
    toggleHamburger() {
      document.querySelector('.close-sidebar-btn').click() // for some reason '$refs' not working
    },
    toggleSidebarHover(add, className) {
      const el = document.body
      this.sidebarActive = !this.sidebarActive

      this.windowWidth = document.documentElement.clientWidth

      if (this.windowWidth > '992') {
        add === 'add' ? el.classList.add(className) : el.classList.remove(className)
      }
    },
    getWindowWidth() {
      const el = document.body

      this.windowWidth = document.documentElement.clientWidth

      if (this.windowWidth < '1350') {
        el.classList.add('closed-sidebar', 'closed-sidebar-md')
      } else {
        el.classList.remove('closed-sidebar', 'closed-sidebar-md')
      }
    }
  }
}



export default {
  name: 'HeaderUserArea',
  props: {
    loggedIn: Boolean,
    user: Object
  },
  methods: {
    async logout() {
      await this.$auth.logout()
    }
  }
}

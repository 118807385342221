import { CostCardItemRead } from '~/service/api'

export class CustomCostCardItem extends CostCardItemRead {
  constructor(data: undefined | any = {}) {
    super()
    this.id = data.id || null
    this.note = data.note || null
    this.price = data.price || null
    this.relative_discount = data.relative_discount || null
    this.pricing_product = data.pricing_product || null
    this.hotel_discount_percentage = data.hotel_discount_percentage || null
    this.relative_discount_amount = data.relative_discount_amount || null
    this.price_sum = data.price_sum || null
    this.hotel_price_sum = data.hotel_price_sum || null
    this.hotel_discount_amount = data.hotel_discount_amount || null
    this.sort_order = data.sort_order || null
    this.quantity = data.quantity || null
    this.amount_of_time = data.amount_of_time || 0
    this.card = data.card || null
    this.highlight = data.highlight || false
    this.created_at = data.created_at || false
    this.created_by_user = data.created_by_user || false
    this.start_time = data.start_time || null
    this.end_time = data.end_time || null
  }
}

import { DefineActions, DefineGetters, DefineMutations } from 'vuex-type-helper'
import { createNamespacedHelpers } from 'vuex'
import { EnumMultiOfferPrintLanguage, OfferService, OfferUser } from '~/service/api'
import { CustomOffer, CustomOfferDetail } from '~/models/Offers'
import { getObjectBranch } from '~/helpers/functions'
import sortNormalize from '~/helpers/urlSortParamsNormalize'

export interface State {
  detail: CustomOfferDetail
  offerListAbortController: AbortController
  draft: CustomOffer
  filter: {
    q: string | null,
    eventNumberYear: number | null
    eventStartGte: string
    eventEndLte: string
    hasInvoice: boolean | null
    isSponsorshipEvent: boolean | null
    company: string | null
    state: string | null,
    sortBy: Array<string> | null,
    sortDesc: Array<boolean> | null,
    users: Array<string> | null,
    jobs: Array<string> | null
    nonActiveOffers: boolean | null
    eventNumber: string | null
  }
  list: CustomOffer[]
  printData: {
    format: string | null
    language: string | null
    printSet: number | null
  }
  pagination: { count: null, page: number, page_size: number }
  loading: boolean,
  offerCardsUpdated: string | null,
  selected: string[]
}

export interface Actions {
  get: string
  create: void
  copy: void
  variant: void
  getList: object | {}
  generateEXCEL: object | {}
  generatePDF: object | {}
  generateMultiPDF: object | {}
  update: void
  uploadAttachment: any
  deleteAllAttachments: void
}

export interface Mutations {
  bulkUpdateOffer: { [key: string]: any }
  clear: void,
  clearDraft: void,
  clearList: void,
  set: CustomOfferDetail,
  setDraft: CustomOffer,
  setCardsUpdated: void
  setList: CustomOffer[],
  setLoading: boolean,
  setPagination: { count: null, page: 1, page_size: 20 },
  setFilter: {
    q: string | null
    eventNumberYear: number | null
    eventStartGte: string
    eventEndLte: string
    hasInvoice: boolean | null
    isSponsorshipEvent: boolean | null
    company: string | null
    state: string | null,
    sortBy: Array<string> | null,
    sortDesc: Array<boolean> | null,
    users: Array<string> | null,
    jobs: Array<string> | null
    nonActiveOffers: boolean | null,
    eventNumber: string | null
  },
  updateFilterField: { field: string, value: any },
  updateFilterFields: { field: string, value: any }[],
  updateOfferField: { field: string, value: any },
  updatePrintDataField: { field: string, value: any },
  updateDraftField: { field: string, value: any },
  updateDraftUser: { user: string, index: number },
  updateDraftUserJob: { job: string, index: number },
  updateDraftUserActive: { active: boolean, index: number },
  setObject: { path: string, value: any }
  setObjectPush: { path: string, value: any }
  setObjectUpdate: { path: string, value: any }
  setObjectSplice: { path: string, value: any },
  setSelected: string[],
  addNewUser: void,
  deleteUser: { index: number }
  setOfferListAbortController: AbortController
}

export interface Getters { }

export function state() {
  const state: State = {
    detail: new CustomOfferDetail(),
    offerListAbortController: new AbortController(),
    filter: {
      q: null,
      eventNumberYear: null,
      eventStartGte: '',
      eventEndLte: '',
      hasInvoice: null,
      isSponsorshipEvent: null,
      company: null,
      state: null,
      sortBy: [],
      sortDesc: [],
      users: null,
      jobs: [],
      nonActiveOffers: false,
      eventNumber: null
    },
    draft: new CustomOffer(),
    list: [],
    printData: {
      format: null,
      language: null,
      printSet: null
    },
    pagination: { count: null, page: 1, page_size: 20 },
    loading: false,
    offerCardsUpdated: null,
    selected: []
  }
  return state
}

export const getters: DefineGetters<Getters, State> = {}

export const actions: DefineActions<Actions, State, Mutations, Getters> = {
  async get({ commit }, id) {
    await OfferService.offerRead({
      id
    }).then((res) => {
      commit('set', res)
      commit('setDraft', new CustomOffer(JSON.parse(JSON.stringify(res)))) // TODO DOCELA HACK
    })
  },
  async copy({ commit, state }) {
    await OfferService.offerCopy({
      data: state.detail,
      id: state.detail.id ?? ''
    }).then((res) => {
      commit('set', res as unknown as CustomOfferDetail)
    })
  },
  async create({ commit, state }) {
    await OfferService.offerCreate({
      data: state.draft
    }).then((res) => {
      commit('set', res as unknown as CustomOfferDetail)
    })
  },
  getList({ commit, state }, params: any) {
    state.offerListAbortController.abort()
    const newController = new AbortController()
    commit('setOfferListAbortController', newController)
    commit('setLoading', true)
    OfferService.offerList({
      ...params,
      ordering: params?.sortBy && params?.sortDesc ? sortNormalize(params.sortBy, params.sortDesc) as string : '',
      pageSize: state.pagination.page_size,
      page: state.pagination.page,
      jobs: params?.jobs || null,
      users: params?.users?.join() || null,
      nonActive: params?.nonActiveOffers || false
    }, {
      signal: state.offerListAbortController.signal
    }).then((res) => {
      commit('setList', res.results)
      commit('setPagination', res.pagination)
      commit('setLoading', false)
    }).catch((err) => {
      if (err.code !== 'ERR_CANCELED') {
        throw err
      }
    })
  },
  async generateEXCEL({ state }) {
    return await OfferService.offerExcel({
      id: state.detail.id ?? '',
      language: state.printData.language || undefined,
      printSet: state.printData.printSet || undefined
    }, { responseType: 'blob' })
  },
  async generatePDF({ state }) {
    return await OfferService.offerPdf({
      id: state.detail.id ?? '',
      language: state.printData.language || undefined,
      printSet: state.printData.printSet || undefined
    }, { responseType: 'blob' })
  },
  async generateMultiPDF({ state }) {
    return await OfferService.offerMultiplePdf({
      data: {
        offer_ids: state.selected,
        language: <EnumMultiOfferPrintLanguage>state.printData.language || 'en',
        print_set: <1 | 2 | 3 | 4>state.printData.printSet || 1
      }
    }, { responseType: 'blob' })
  },
  async update({ state, commit }) {
    await OfferService.offerUpdate({
      data: state.draft,
      id: state.draft.id ?? ''
    }).then((res) => {
      commit('bulkUpdateOffer', res)
    })
  },
  async variant({ commit, state }) {
    await OfferService.offerVariant({
      data: state.detail,
      id: state.detail.id ?? ''
    }).then((res) => {
      commit('set', res as unknown as CustomOfferDetail)
    })
  },
  async uploadAttachment({ state }, fileData) {
    await OfferService.offerUploadOfferAttachment({
      file: fileData,
      id: state.detail.id ?? ''
    })
  },
  async deleteAllAttachments({ state }) {
    await OfferService.offerDeleteOfferAttachment({
      id: state.draft.id ?? ''
    })
  }
}

export const mutations: DefineMutations<Mutations, State> = {
  clear(state) {
    state.detail = new CustomOfferDetail()
  },
  clearDraft(state) {
    state.draft = new CustomOffer()
  },
  clearList(state) {
    state.list = []
  },
  set(state, offer) {
    state.detail = offer
  },
  setDraft(state, offer) {
    state.draft = { ...offer, comment: '' }
  },
  setList(state, list) {
    state.list = list
  },
  setLoading(state, loading) {
    state.loading = loading
  },
  setFilter(state, filter) {
    state.filter = filter
  },
  setPagination(state, pagination) {
    state.pagination = pagination
  },
  updateFilterField(state: State, { field, value }) {
    (state.filter as any)[field] = value
  },
  updateFilterFields(state: State, fields: { field: any; value: any }[]) {
    fields.forEach((f) => { (state.filter as any)[f.field] = f.value })
  },
  updateOfferField(state, { field, value }) {
    (state.detail as any)[field] = value
  },
  updatePrintDataField(state, { field, value }) {
    (state.printData as any)[field] = value
  },
  updateDraftField(state, { field, value }) {
    (state.draft as any)[field] = value
  },
  updateDraftUser(state, { user, index }) {
    (state.draft as any).offer_users[index].user = user
  },
  updateDraftUserJob(state, { job, index }) {
    (state.draft as any).offer_users[index].job = job
  },
  updateDraftUserActive(state, { active, index }) {
    (state.draft as any).offer_users[index].active = active
  },
  bulkUpdateOffer(state, object) {
    // hromadný update offer objektu
    for (const property in object) {
      if (property in state.detail) {
        (state.detail as any)[property] = object[property]
      }
    }
  },
  setObjectPush(state, data) {
    const schema = getObjectBranch(state.detail, data.path)
    if (Array.isArray(data.value)) {
      schema.push(...data.value)
      return
    }
    schema.push(data.value)
  },
  setObjectUpdate(state, data) {
    const schema = getObjectBranch(state.detail, data.path)
    for (const property in data.value) {
      schema[property] = data.value[property]
    }
  },
  setObjectSplice(state, data) {
    const schema = getObjectBranch(state.detail, data.path)
    let arrayData = []
    arrayData = Array.isArray(data.value) ? data.value : [data.value]
    // seřazení array desc
    arrayData = arrayData.sort(function(a: number, b: number) {
      return a - b
    })
    // mažu odzadu podle indexů, aby se mi nerozhodily indexy
    for (let i = arrayData.length - 1; i >= 0; i--) {
      schema.splice(arrayData[i], 1)
    }
  },
  setObject(state, data) {
    let schema = getObjectBranch(state.detail, data.path)

    if (Array.isArray(data.value)) {
      // pokud je hodnota array je potřeba nejdříve všechny smazat a pak pushnout do array
      // jiným způsobem nefunguje reaktivita
      schema.splice(0, data.value.length)
      schema.push(...data.value)
      return
    }
    schema = data.value
  },
  setCardsUpdated(state): void {
    state.offerCardsUpdated = new Date().toISOString()
  },
  setSelected(state, ids) {
    state.selected = ids
  },
  addNewUser(state: State) {
    if (!state.draft.offer_users) {
      state.draft.offer_users = []
    }
    state.draft.offer_users.push(new OfferUser({ user: null, job: null, active: false }))
  },
  deleteUser(state, { index }) {
    (state.draft as CustomOffer).offer_users?.splice(index, 1)
  },
  setOfferListAbortController(state, controller) {
    state.offerListAbortController = controller
  }
}

export const {
  mapState,
  mapGetters,
  mapMutations,
  mapActions
} = createNamespacedHelpers<State, Getters, Mutations, Actions>('offers')

export const offers = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

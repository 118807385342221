export default function handleErrorResponse(response) {
  if (response.status === 401) {
    window.$nuxt.$auth.logout()
    return {}
  }

  if (response.status === 403) {
    window.$nuxt.$toast.error('Na tuto operaci nemáte oprávnění, nyní Vás odhlasíme.')
    setTimeout(() => { location.href = '/#/auth/login/' }, 2000)
  }

  if (response.status !== 400 || !response.data) {
    return { message: 'Server error', errorObject: {} }
  }

  let message = ''
  for (const key in response.data) {
    message += `${key}: ${response.data[key][0]}\n`
  }

  return {
    message: response.data.non_field_errors || message || 'Invalid request',
    errorObject: response.data
  }
}

import { DefineActions, DefineGetters, DefineMutations } from 'vuex-type-helper'
import { createNamespacedHelpers } from 'vuex'
import { PersonalCardItemService } from '~/service/api'
import { CustomPersonalCardItem } from '~/models/PersonalItem'

export interface State {
  detail: CustomPersonalCardItem,
  draft: CustomPersonalCardItem,
  list: CustomPersonalCardItem[]
  pagination: {}
}

export interface Actions {
  bulkDelete: []
  create: void
  update: void
  delete: string
}

export interface Mutations {
  clear: void,
  clearDraft: void,
  clearList: void,
  set: CustomPersonalCardItem,
  setDraft: CustomPersonalCardItem,
  setList: CustomPersonalCardItem[],
  setPagination: {},
  updatePersonField: { field: string, value: any }
  updatePersonDraftField: { field: string, value: any }
}

export interface Getters {}

export function state() {
  const state: State = {
    detail: new CustomPersonalCardItem(),
    draft: new CustomPersonalCardItem(),
    list: [],
    pagination: { count: null, next: null, previous: null, page: null, num_pages: null, page_size: null }
  }
  return state
}

export const getters: DefineGetters<Getters, State> = {}

export const actions: DefineActions<Actions, State, Mutations, Getters> = {
  async create({ state, commit }) {
    await PersonalCardItemService.personalCardItemCreate({
      data: state.draft
    }).then((response) => {
      commit('set', response)
    })
  },
  async update({ state, commit }) {
    await PersonalCardItemService.personalCardItemUpdate({
      id: state.draft.id ?? '',
      data: state.draft
    }).then((response) => {
      commit('set', response)
    })
  },
  async delete({}, id) {
    await PersonalCardItemService.personalCardItemDelete({
      id
    })
  },
  async bulkDelete({}, data) {
    for (const id of data) {
      await PersonalCardItemService.personalCardItemDelete({
        id
      })
    }
  }
}

export const mutations: DefineMutations<Mutations, State> = {
  clear(state) {
    state.detail = new CustomPersonalCardItem()
  },
  clearDraft(state) {
    state.draft = new CustomPersonalCardItem()
  },
  clearList(state) {
    state.list = []
  },
  set(state, person) {
    state.detail = person
  },
  setDraft(state, person) {
    state.draft = new CustomPersonalCardItem(person)
  },
  setList(state, list) {
    state.list = list
  },
  setPagination(state, pagination) {
    state.pagination = pagination
  },
  updatePersonField(state, { field, value }) {
    (state.detail as any)[field] = value
  },
  updatePersonDraftField(state, { field, value }) {
    (state.draft as any)[field] = value
  }
  // addToSelected(state, value) {
  //   state.selectedList.push(value)
  // },
  // removeFromSelected(state, value) {
  //   state.selectedList.splice(state.selectedList.indexOf(value), 1)
  // }
}

export const {
  mapState,
  mapGetters,
  mapMutations,
  mapActions
} = createNamespacedHelpers<State, Getters, Mutations, Actions>('person')

export const person = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

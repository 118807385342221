import { DefineActions, DefineGetters, DefineMutations } from 'vuex-type-helper'
import { createNamespacedHelpers } from 'vuex'
import { PricingProductService } from '~/service/api'
import { CustomPricingProduct } from '~/models/PricingProduct'
import sortNormalize from '~/helpers/urlSortParamsNormalize'

export interface State {
  detail: CustomPricingProduct,
  draft: CustomPricingProduct,
  filter: {
    q: string | null,
    category__id: string | null
    deleted: boolean | null,
    sortBy: Array<string> | null,
    sortDesc: Array<boolean> | null
  }
  list: CustomPricingProduct[]
  pagination: { count: null, page: number, page_size: number }
  printData: {
    format: string | null
    language: string | null
    company: string | null
    surcharge: number | null
  }
  loading: boolean
}

export interface Actions {
  create: void,
  get: string,
  getList: { },
  update: void,
  delete: string,
  generateEXCEL: object | {},
  generatePDF: object | {}
}

export interface Mutations {
  clear: void,
  clearDraft: void,
  clearList: void,
  clearFilter: void,
  clearPrintData: void,
  set: CustomPricingProduct,
  setDraft: CustomPricingProduct,
  setList: CustomPricingProduct[],
  setLoading: boolean,
  setPagination: { count: null, page: number, page_size: number },
  setFilter: {
    q: string | null,
    category__id: string | null
    deleted: boolean | null,
    sortBy: Array<string> | null,
    sortDesc: Array<boolean> | null
  },
  updateDraftField: { field: string, value: any },
  updatePriceListField: { field: string, value: any }
  updateFilterField: { field: string, value: any }
  updatePrintDataField: { field: string, value: any },
}

export interface Getters {
  pricingProductOptions: void
}

export function state() {
  const state: State = {
    detail: new CustomPricingProduct(),
    filter: {
      q: null,
      category__id: null,
      deleted: false,
      sortBy: [],
      sortDesc: []
    },
    draft: new CustomPricingProduct(),
    list: [],
    pagination: { count: null, page: 1, page_size: 20 },
    printData: {
      format: null,
      language: null,
      company: null,
      surcharge: null
    },
    loading: false
  }
  return state
}

export const getters: DefineGetters<Getters, State> = {
  pricingProductOptions(state) {
    return state.list.map(product => ({ text: product.title, value: product.id }))
  }
}

export const actions: DefineActions<Actions, State, Mutations, Getters> = {
  async create({ state }) {
    return await PricingProductService.pricingProductCreate(
      {
        data: state.draft
      }
    )
  },
  async get({ commit }, id) {
    await PricingProductService.pricingProductRead({
      id
    }).then(res => commit('set', res))
  },
  async getList({ commit, state }, params: any) {
    commit('setLoading', true)
    await PricingProductService.pricingProductList({
      ...params,
      pageSize: state.pagination.page_size,
      page: state.pagination.page,
      ordering: params?.sortBy && params?.sortDesc ? sortNormalize(params.sortBy, params.sortDesc) as string : '',
      deleted: false
    }).then(
      (res) => {
        if ('pagination' in res) {
          commit('setList', res.results)
          commit('setPagination', res.pagination)
          commit('setLoading', false)
        } else {
          commit('setList', res)
        }
      }
    )
  },
  async update({ commit, state }) {
    await PricingProductService.pricingProductUpdate({
      data: state.draft,
      id: state.draft.id ?? ''
    }).then(res => commit('set', res))
  },
  async delete({}, id) {
    await PricingProductService.pricingProductDelete({
      id
    })
  },
  async generateEXCEL({ state }) {
    return await PricingProductService.pricingProductExcel({
      categoryId: state.detail.id,
      language: state.printData.language || undefined,
      companyId: state.printData.company || undefined,
      surcharge: state.printData.surcharge || undefined
    }, { responseType: 'blob' })
  },
  async generatePDF({ state }) {
    return await PricingProductService.pricingProductPdf({
      categoryId: state.detail.id,
      language: state.printData.language || undefined,
      companyId: state.printData.company || undefined,
      surcharge: state.printData.surcharge || undefined
    }, { responseType: 'blob' })
  }
}

export const mutations: DefineMutations<Mutations, State> = {
  clear(state) {
    state.detail = new CustomPricingProduct()
  },
  clearDraft(state) {
    state.draft = new CustomPricingProduct()
  },
  clearList(state) {
    state.list = []
  },
  clearFilter(state) {
    state.filter = {
      q: null,
      category__id: null,
      deleted: false,
      sortBy: [],
      sortDesc: []
    }
  },
  clearPrintData(state) {
    state.printData = {
      format: null,
      language: null,
      company: null,
      surcharge: null
    }
  },
  set(state, CustomPricingProduct) {
    state.detail = CustomPricingProduct
  },
  setDraft(state, pricingProduct) {
    state.draft = new CustomPricingProduct(pricingProduct)
  },
  setList(state, list) {
    state.list = list
  },
  setLoading(state, loading) {
    state.loading = loading
  },
  setFilter(state, filter) {
    state.filter = filter
  },
  setPagination(state, pagination) {
    state.pagination = pagination
  },
  updateDraftField(state, { field, value }) {
    (state.draft as any)[field] = value
  },
  updatePriceListField(state, { field, value }) {
    (state.detail as any)[field] = value
  },
  updateFilterField(state: State, { field, value }) {
    (state.filter as any)[field] = value
  },
  updatePrintDataField(state, { field, value }) {
    (state.printData as any)[field] = value
  }
}

export const {
  mapState,
  mapGetters,
  mapMutations,
  mapActions
} = createNamespacedHelpers<State, Getters, Mutations, Actions>('priceList')

export const priceList = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
